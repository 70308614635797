<template>
  <div>
    <div class="d-flex fill-width align-center justify-space-between grey lighten-3 py-2 mb-3 px-4">
      <span class="font-weight-bold pink--text caption">Completed Purchase History</span>
      <v-btn x-small outlined color="pink" dark class="no-print rounded-pill text-capitalize" @click.prevent="PurchaseShow()">
        + New
      </v-btn>
    </div>
    <div class="d-flex teal--text fill-width justify-space-between align-center px-4 pb-2">
      Progress {{ QtyLeftTotal.completed }}/{{ QtyLeftTotal.total }} qty
      <div class="d-flex align-center">
        <v-progress-circular
          :rotate="-90"
          :size="45"
          :width="3"
          :value="QtyLeftTotal.completed_percent"
          color="teal"
        >
          <span class="font-weight-bold" style="font-size: .55rem;">{{ QtyLeftTotal.completed_percent.toFixed(1) }}%</span>
        </v-progress-circular>
      </div>
    </div>
    <div>
      <div v-if="!purchase.length" class="d-flex fill-width justify-center pa-4 red--text">
        no data
      </div>
      <div v-for="(p, iP) in purchase" :key="'purchase-' + iP">
        <div class="d-flex justify-space-between align-center px-4">
          <span class="caption-small font-weight-bold">#EB/{{ trx }}/PURCHASE/{{ p.id }} - {{ $localDT(p.created, 'datetime') }}</span>
          <div class="d-flex align-center">
            <v-btn x-small icon color="red" dark class="no-print text-capitalize mr-2" @click.prevent="PurchaseDelete(p.id)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn x-small icon color="indigo darken-2" dark class="no-print text-capitalize" @click.prevent="PurchaseShow(p)">
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider />
        <div class="pt-1 pb-2 px-4">
          <div v-for="(pi, iPi) in p.items" :key="'purchase-item-list-' + iPi + '-' + iP" class="d-flex fill-width align-center justify-space-between caption-small">
            {{ (iPi + 1) }}. {{ pi.product_name }}
            <span>
              {{ pi.quantity }}pcs
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialogNewPurchase.show"
      scrollable
      max-width="420px"
      content-class="no-print"
    >
      <v-card v-if="dialogNewPurchase.data" light>
        <v-card-title class="body-2 font-weight-bold">
          <v-icon class="mr-2" small>
            {{ dialogNewPurchase.data.id ? 'mdi-pencil' : 'mdi-plus' }}
          </v-icon>
          {{ dialogNewPurchase.data.id ? 'Update' : 'Create New' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-2 px-2">
          <v-row no-gutters>
            <v-col cols="6" class="pl-2 pr-1 d-flex align-center">
              <v-select
                v-model="purchaseItemSelected"
                label="Select Product"
                :items="cart"
                item-text="product_name"
                outlined
                block
                dense
                return-object
                autocomplete="off"
                hide-details
                @change="purchaseItemSelected ? (purchaseItemSelectedQuantity = QtyLeft(purchaseItemSelected.id)) : false"
              />
            </v-col>
            <v-col cols="3" class="pl-2 pr-1 d-flex align-center">
              <v-text-field
                v-model.number="purchaseItemSelectedQuantity"
                label="Qty"
                outlined
                block
                dense
                return-object
                autocomplete="off"
                hide-details
                @keypress="$NumberOnly"
                @change="parseInt(purchaseItemSelectedQuantity) ? false : purchaseItemSelectedQuantity = 1"
                @blur="parseInt(purchaseItemSelectedQuantity) ? false : purchaseItemSelectedQuantity = 1"
              />
            </v-col>
            <v-col cols="3" class="pr-2 py-2 pl-1 d-flex align-center">
              <v-btn depressed dark block class="rounded-md text-capitalize caption" color="pink" @click.prevent="AddPurchaseItem()">
                + Add
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col v-for="(i, iItem) in dialogNewPurchase.data.items" :key="'purchase-item-' + iItem" cols="12" class="px-2">
              <v-card class="d-flex fill-width align-center justify-space-between py-1 px-2 caption my-1" outlined>
                {{ (iItem + 1) }}. {{ i.product_name }}
                <div class="d-flex justify-center">
                  <div class="d-flex align-center">
                    <input
                      v-model.number="dialogNewPurchase.data.items[iItem].quantity"
                      type="number"
                      class="mr-1 grey lighten-3 rounded-lg pl-1 caption"
                      style="max-width: 55px;outline:none;"
                      @keypress="$NumberOnly"
                      @change="parseInt(dialogNewPurchase.data.items[iItem].quantity) ? false : dialogNewPurchase.data.items[iItem].quantity = 1"
                      @blur="parseInt(dialogNewPurchase.data.items[iItem].quantity) ? false : dialogNewPurchase.data.items[iItem].quantity = 1"
                    >pcs
                  </div>
                  <v-btn small icon depressed color="red" class="ml-2" @click.prevent="PurchaseItemDelete(i, iItem)">
                    <v-icon x-small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            text
            small
            dark
            class="text-capitalize"
            @click="dialogNewPurchase.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogNewPurchase.data"
            depressed
            color="orange"
            dark
            small
            class="rounded-pill px-8 caption font-weight-bold text-capitalize"
            @click="PurchaseProcess()"
          >
            {{ dialogNewPurchase.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// const newPurchaseItem = {
//   id: null,
//   trx_purchase: 0,
//   trx_purchase_item: 0,
//   quantity: 0,
//   created: null
// }
export default {
  props: {
    trx: {
      type: Number,
      default: 0
    },
    cart: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    purchase: [],
    purchaseTotal: 0,
    dialogNewPurchase: {
      show: false,
      data: null
    },
    purchaseItemSelected: null,
    purchaseItemSelectedQuantity: 1
  }),
  computed: {
    QtyLeftTotal () {
      let qtyCart = 0
      let qtyCompleted = 0
      for (let c = 0; c < this.cart.length; c++) {
        qtyCart += parseInt(this.cart[c].quantity)
        qtyCompleted += this.QtySum(this.cart[c].id)
      }
      return {
        total: qtyCart,
        completed: qtyCompleted,
        completed_percent: (qtyCompleted * 100) / qtyCart
      }
    }
  },
  mounted () {
    this.PurchaseGet()
  },
  methods: {
    QtyLeft (id) {
      const c = this.cart.find(r => parseInt(r.id) === parseInt(id))
      if (c) {
        const qty = this.QtySum(id)
        return parseInt(c.quantity) - qty
      }
      return 0
    },
    QtySum (id) {
      let qty = 0
      for (let i = 0; i < this.purchase.length; i++) {
        const items = this.purchase[i].items
        for (let ii = 0; ii < items.length; ii++) {
          const item = items[ii]
          if (parseInt(item.trx_purchase_item) === parseInt(id)) {
            qty += (parseInt(item.quantity) || 0)
          }
        }
      }
      return qty
    },
    AddPurchaseItem () {
      if (!this.purchaseItemSelected) {
        this.$store.commit('SNACKBAR', { show: true, text: 'No product selected!' })
        return false
      }
      if (!parseInt(this.purchaseItemSelectedQuantity) || parseInt(this.purchaseItemSelectedQuantity) < 1) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid quantity!' })
        return false
      }
      const findCart = this.cart.find(r => parseInt(r.id) === this.purchaseItemSelected.id)
      if (findCart) {
        const findItemExist = this.dialogNewPurchase.data.items.find(r => parseInt(r.trx_purchase_item) === findCart.id)
        if (!findItemExist) {
          this.dialogNewPurchase.data.items.push({
            id: null,
            trx_purchase: this.dialogNewPurchase.data.id,
            trx_purchase_item: findCart.id,
            product_name: findCart.product_name,
            quantity: parseInt(this.purchaseItemSelectedQuantity)
          })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Product already exist!' })
        }
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Product not in the list!' })
      }
    },
    PurchaseShow (purchase) {
      // this.dialogNewPurchase.data = null
      this.purchaseItemSelected = null
      this.purchaseItemSelectedQuantity = 1
      if (purchase) {
        this.dialogNewPurchase.data = Object.assign({}, purchase)
      } else {
        this.dialogNewPurchase.data = Object.assign({}, {
          id: null,
          trx: 0,
          items: []
        })
      }
      this.dialogNewPurchase.show = true
    },
    PurchaseGet () {
      if (this.trx) {
        this.purchase = []
        this.$store.dispatch('PURCHASE_GET', this.trx)
          .then((res) => {
            if (res.status) {
              this.purchase = res.data.data.data.map((r) => {
                const forItems = r.purchase_items ? r.purchase_items.split('|-|') : ['']
                r.items = []
                if (forItems[0]) {
                  for (let x = 0; x < forItems.length; x++) {
                    const item = forItems[x].split('#-#')
                    r.items.push({
                      id: item[0],
                      trx_purchase: item[1],
                      trx_purchase_item: item[2],
                      quantity: parseInt(item[3]) || 0,
                      product_name: item[4] || '-'
                    })
                  }
                }
                return r
              }) || []
              this.purchaseTotal = res.data.data.total || 0
            }
          })
      }
    },
    PurchaseProcess () {
      const params = Object.assign({}, this.dialogNewPurchase.data)
      params.trx = this.trx
      this.$store.dispatch('PURCHASE_PROCESS', params)
        .then((res) => {
          if (res.status) {
            this.PurchaseGet()
            this.$store.commit('SNACKBAR', { show: true, text: 'Purchase ' + (this.dialogNewPurchase.data.id ? 'Updated!' : 'Created!') })
            this.dialogNewPurchase.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
          }
        })
    },
    PurchaseDelete (id) {
      if (!confirm('Are you sure want to delete?')) {
        return false
      }
      this.$store.dispatch('PURCHASE_DELETE', id)
        .then((res) => {
          if (res.status) {
            this.$store.commit('SNACKBAR', { show: true, text: 'Deleted!' })
            this.PurchaseGet()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
          }
        })
    },
    async PurchaseItemDelete (purchaseItem, index) {
      if (!this.dialogNewPurchase.show) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Ops, no purchase!' })
        return false
      }
      let next = true
      if (purchaseItem.id) {
        if (!confirm('Are you sure want to delete this item?')) {
          return false
        }
        await this.$store.dispatch('PURCHASE_ITEM_DELETE', purchaseItem.id)
          .then((res) => {
            if (res.status) {
              this.$store.commit('SNACKBAR', { show: true, text: 'Deleted!' })
              this.PurchaseGet()
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
              next = false
            }
          })
      }
      if (next) {
        this.dialogNewPurchase.data.items.splice(index, 1)
      }
    }
  }
}
</script>
