import { render, staticRenderFns } from "./InvoiceViewPurchase.vue?vue&type=template&id=07b03206"
import script from "./InvoiceViewPurchase.vue?vue&type=script&lang=js"
export * from "./InvoiceViewPurchase.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports